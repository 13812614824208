import React from 'react'
import ReactDOM from 'react-dom/client'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import { Provider } from 'react-redux'

import { Auth0Provider } from '@auth0/auth0-react'

import App from './App'
import reportWebVitals from './reportWebVitals'
import { setupStore } from './store/configureStore'

import './index.scss'
import './i18n'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
const store = setupStore()

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <GoogleReCaptchaProvider reCaptchaKey={`${process.env.REACT_APP_RECAPTCHA_KEY}`} language="sv">
        <Auth0Provider
          domain={`${process.env.REACT_APP_AUTH0_DOMAIN}`}
          clientId={`${process.env.REACT_APP_AUTH0_CLIENT_ID}`}
          authorizationParams={{
            audience: process.env.REACT_APP_AUTH0_AUDIENCE,
            redirect_uri: `${window.location.origin}/orle`,
          }}
        >
          <App />
        </Auth0Provider>
      </GoogleReCaptchaProvider>
    </Provider>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
