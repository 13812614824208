import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faFacebook } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Analytics from '../../Utils/analytics'
import ChangeLanguage from '../ChangeLanguage/ChangeLanguage'
import SuggestionModal from '../SuggestionModal/SuggestionModal'
import s from './Footer.module.scss'

export interface FooterProps {
  bigFooter: boolean
}

const Footer = ({
  bigFooter = false,
}: FooterProps): JSX.Element => {

  const { t } = useTranslation()
  const [modalIsOpen, setModalIsOpen] = useState(false)

  const toggleModal = () => {
    if (!modalIsOpen) {
      Analytics.logEvent('Suggestion', 'Clicked', 'Suggestion button', true)
    }
    setModalIsOpen(!modalIsOpen)
  }

  return (
    <div className={`${s.ForestContainer}`}>
      { bigFooter && (
      <>
      <div className={s.SuggestContainer}>
        <div className={s.Texts}>
          <h4>{t('suggestion_header')}</h4>
          <button className={s.SuggestBtn} 
             onClick={toggleModal}>
              {t('suggestion_btn_text')}
          </button>
          {modalIsOpen && (
            <SuggestionModal modalIsOpen={modalIsOpen} toggleModal={toggleModal} />
          )}
        </div>
      </div>
      
      <div className={s.Wrapper}>
        <div className={s.BigFooterContainer}>
          <div className={s.Column}>
            <p className={s.TranslateTextHeader}>På jamska</p>
            <ChangeLanguage />
          </div>
          <div className={s.Column}>
            <ul className={`${s.ListLinks} ${s.List}`}>
              <li className={s.FooterExternalLinkHeader}>{t('social_medias')}</li>
              <li>
                <a href="https://www.facebook.com/jamskacom/" target="_blank" rel="noreferrer">
                  <FontAwesomeIcon icon={faFacebook as IconProp} /> Facebook
                </a>
              </li>
            </ul>
          </div>
          <div className={s.Column}>
            <ul className={`${s.ListLinks} ${s.List}`}>
              <li className={s.FooterExternalLinkHeader}>Villkor</li>
              <li><a href="/cookie-policy">Cookie policy</a></li>
            </ul>
          </div>
        </div>
      </div>
      </>
      )}
      <footer className={bigFooter ? s.BigFooterBottom : s.SmallFooter} data-testid="Footer">
        <p className={s.CopyrightText}>&copy; Jamska.com</p>
      </footer>
    </div>
  )
}

export default Footer
