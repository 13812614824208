// usePost.tsx

import { useState } from 'react';

const usePost = (url: string) => {
  const [error, setError] = useState<Error | null>(null)
  const [loading, setLoading] = useState(false)
  const [response, setResponse] = useState<any>(null)

  const post = async (body: any) => {
    setLoading(true)
    try {
      const res = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      })
      if (res.status === 204) {
        setLoading(false)
        setResponse({ status: res.status })
        return
      }
      if (res.status === 500) {
        setError(new Error('Internal Server Error'))
        setLoading(false)
        return
      }
      const data = await res.json()
      setLoading(false)
      setResponse({ status: res.status, data })
    } catch (error: any) {
      setError(error)
      setLoading(false)
      throw error
    }
  }

  return { response, post, loading, error }
}

export default usePost