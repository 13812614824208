import { useCallback, useEffect, useState } from "react"
import { GoogleReCaptcha } from "react-google-recaptcha-v3"
import { useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"

import { faHeart } from "@fortawesome/free-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import usePost from "../../Utils/network/usePost"
import Loading from "../Loading/Loading"
import Modal from "../Modal/Modal"
import s from './SuggestionModal.module.scss'

type FormValues = {
  name: string
  email: string
  suggestion: string
}

interface SuggestionModalProps {
  modalIsOpen: boolean
  toggleModal: () => void
}

const SuggestionModal = ({
  modalIsOpen,
  toggleModal,
}: SuggestionModalProps): JSX.Element => {

  const { t } = useTranslation()
  
  const { register,
     handleSubmit,
     formState: { isValid },
  } = useForm<FormValues>()

  const [token, setToken] = useState('')
  const [refreshReCaptcha, setRefreshReCaptcha] = useState(false)
  const { response, post, loading, error } = usePost(process.env.REACT_APP_API_URL + "/suggestion/suggestion")
  const [showConfirmation, setShowConfirmation] = useState(false)
  const [showError, setShowError] = useState(false)

  const onVerify = useCallback((token: string) => setToken(token), [])

  const submitForm = async (data: FormValues) => {
    post({
      name: data.name,
      email: data.email,
      suggestion: data.suggestion,
      reCaptchaToken: token
    })    
    setRefreshReCaptcha(r => !r)
  }

  useEffect(() => {
    if (response && response.status === 204 && !loading) {
      setShowConfirmation(true)
      setTimeout(() => {
        toggleModal()
      }, 2500)
    }
    else if (error && !loading) {
      setShowError(true)
    }
  }, [loading, response, error, toggleModal])

  return (
    <Modal 
      show={modalIsOpen} 
      onClose={toggleModal}
      showCloseBtn={true}
      customStyles={{background: 'linear-gradient(95deg, rgb(142, 172, 191) 7%, rgb(37, 130, 195) 89%)'}}>
      <div className={s.ModalWrapper}>
        <div className={s.HeaderWrapper}>
          <h1 className={s.FormHeader}>
            <FontAwesomeIcon icon={faHeart}/>
            {t('suggestion_form_header_1')}
            <FontAwesomeIcon icon={faHeart}/>
          </h1>
          <h5 className={s.FormSubHeader}>
          {t('suggestion_form_header_2')}.
          </h5>
        </div>
        { !loading && !showConfirmation && (
          <form className={s.SuggestionForm} onSubmit={handleSubmit(submitForm)}>
            <label className={s.FormLabel}>
            {t('suggestion_form_about_you')}:
            </label>
            <input type="text" 
                   {...register('name', { required: true, maxLength: 20, minLength: 2 })}
                   className={`${s.AboutYouInput}`}
                   placeholder={t('suggestion_form_name_placeholder')} />
            <input type="email" 
                   className={`${s.AboutYouInput}`}
                   {...register("email", {
                    required: true,
                    pattern: {
                      value: /\S+@\S+\.\S+/,
                      message: "Entered value does not match email format"
                    }
                  })}
                  placeholder={t('suggestion_form_email_placeholder')}/>
            <label className={`${s.FormLabel} ${s.SuggestionLabel}`}>
            {t('suggestion_form_about_suggestion')}:
            </label>
            <textarea rows={5} 
                      className={s.SuggestionTextArea}
                      {...register('suggestion', { required: true, maxLength: 300, minLength: 10 })}/>
            <div>
              <GoogleReCaptcha
                onVerify={onVerify}
                refreshReCaptcha={refreshReCaptcha}
              />
            </div>
            <div className={s.CaptchaInfo}>
              This site is protected by reCAPTCHA and the Google
                <a href="https://policies.google.com/privacy">Privacy Policy</a> and
                <a href="https://policies.google.com/terms">Terms of Service</a> apply.
           </div> 
           {showError && (
            <div className={s.ErrorText}>Aj då, något gick fel. Försök igen senare.</div> 
           )}
            <div className={s.FormButtons}>
              <button className={s.CancelButton} onClick={toggleModal}>
                {t('shared_cancel')}
              </button>
              <button 
                className={s.SubmitButton}
                disabled={!isValid}
                type="submit">
                  {t('shared_submit')}
              </button>
            </div>
          </form>
        )}
        { !loading && showConfirmation && (
          <div className={s.ConfirmationWrapper}>
            <h3 className={s.ConfirmationText}>Vi har nu mottagit ditt förslag. Tack igen!</h3>
          </div>
        )}
        { loading && (
          <Loading/>
        )}
      </div>
    </Modal>
  )
} 

export default SuggestionModal