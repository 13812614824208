
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import s from './ChangeLanguage.module.scss'

const ChangeLanguage = (): JSX.Element => {

  const { i18n } = useTranslation()
  const [isChecked, setIsChecked ] = useState(false)

  useEffect(() => {
    isChecked ? i18n.changeLanguage('z') : i18n.changeLanguage('se')
  }, [i18n, isChecked])

  return (
    <span 
      data-testid="change-language"
      className={`${s.Switch} ${isChecked ? s.Checked : ''}`}
      onClick={() => setIsChecked(!isChecked)}>
      <small></small>
      <input type="checkbox" className={s.Hidden} name="" />
    </span>
  )
}

export default ChangeLanguage