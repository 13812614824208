import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { useAuth0 } from '@auth0/auth0-react'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Footer from '../../../components/Footer/Footer'
import s from './UserProfile.module.scss'

export const UserProfile = () => {
  const { user, logout } = useAuth0()
  const navigate = useNavigate()
  const { t } = useTranslation()

  const dailyStreak = parseInt(localStorage.getItem('dailyStreak') || '0', 10)
  const totalWins = parseInt(localStorage.getItem('totalWins') || '0', 10)

  return (
    <>
      <div className={s.profileContainer}>
        <div className={s.Sigill}></div>
        <h2 className={s.Header}>{t('profile_header')}</h2>
        {user && (
          <div className={s.profileBox}>
            <button className={s.backButton} onClick={() => navigate('/orle')} aria-label="Go back to the previous page">
              <FontAwesomeIcon icon={faArrowLeft} />
            </button>
            <p className={s.profileDetail}>
              <strong>Namn:</strong> {user.name}
            </p>
            <p className={s.profileDetail}>
              <strong>Email:</strong> {user.email}
            </p>
            <p className={s.profileDetail}>
              <strong>
                <br />
                {t('profile_stats')}
              </strong>{' '}
              <br /> <br />
            </p>
            <p className={s.profileDetail}>
              {t('streak_message_1')} <strong>{dailyStreak}</strong> {t('streak_message_2')}
            </p>
            <p className={s.profileDetail}>
              {t('times_won_message')}
              <strong>{totalWins}</strong>
            </p>
            <button
              className={s.logoutButton}
              onClick={() =>
                logout({
                  logoutParams: { returnTo: `${window.location.origin}/orle` },
                })
              }
              aria-label="Logout from the application"
            >
              {t('logout')}
            </button>
          </div>
        )}
        <div className={s.BgTrees}></div>
      </div>
      <Footer bigFooter={true} />
    </>
  )
}
